import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdFiberManualRecord } from 'react-icons/md';
import LayoutWrapper from '../../components/layout';
import { dateToString } from '../../utils/date';
import { withForceUpdate } from '../../i18n/with-force-update';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CertificationsPage = ({ data, pageContext: { language }, t }) => {
  // const { t, i18n: { language: lng } } = useTranslation();

  return (
    <LayoutWrapper title={t('Certifications')} showTitle lang={language}>
      <div
        css={{
          display: 'flex',
          flexDirection: `column`,
          alignContent: `center`,
          fontFamily: `LatoLatinWeb`,
          justifyContent: `space-evenly`,
          '& .vertical-timeline::before': {
            width: `3px`,
            background: `darkgray`,
          },
          '& .vertical-timeline-element-content': {
            background: `none`,
            boxShadow: `none`,
          },
          '& .vertical-timeline-element-icon': {
            background: `none`,
            boxShadow: `none`,
          },
          '& .vertical-timeline-element-date': {
            textTransform: `capitalize`,
            color: `darkgrey`,
          },
        }}
      >
        <VerticalTimeline>
          {data.allStrapiCertifications.edges.map((document) => (
            <VerticalTimelineElement
              key={document.node.slug}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(66, 66, 66)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid rgb(66, 66, 66)' }}
              date={`${dateToString(document.node.fromDate, language)} - ${dateToString(
                document.node.toDate,
                language
              )}`}
              iconStyle={{ background: 'none', color: '#dfac47' }}
              icon={<MdFiberManualRecord />}
            >
              <h3 className="vertical-timeline-element-title" css={{ color: `#dfac47` }}>
                {document.node.name}
              </h3>
              <h4 className="vertical-timeline-element-subtitle"> {document.node.location} </h4>
              <ReactMarkdown
                children={document.node.description}
                linkTarget="_blank"
                transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`)}
              />
              {document.node.certificationThumbnail && (
                <div
                  css={{
                    width: `250px`,
                    maxWidth: `100%`,
                    margin: `auto`,
                    '@media (max-width: 768px)': {
                      textAlign: `center`,
                    },
                  }}
                >
                  <GatsbyImage
                    image={getImage(document.node.certificationThumbnail.localFile)}
                    alt={document.node.name}
                    css={{
                      margin: `auto`,
                      width: `100%`,
                      '@media (max-width: 768px)': {
                        width: `100%`,
                        margin: `auto`,
                      },
                    }}
                  />
                </div>
              )}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </LayoutWrapper>
  );
};

export default withForceUpdate(CertificationsPage);

export const query = graphql`
  query FetchCertifications($language: String) {
    allStrapiCertifications(sort: { order: DESC, fields: fromDate }, filter: { locale: { eq: $language } }) {
      edges {
        node {
          name
          description
          location
          slug
          fromDate
          toDate
          createdAt
          certificationThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
